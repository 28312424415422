<template>
  <iframe
    id="unnax"
    v-observer="change"
    name="unnax"
    frameborder="0"
    width="100%"
    :height="height"
    class="pz-large-margin"
    :src="frame_route"
    @load="iframeLoaded"
  />
</template>

<script>
import iFrameResize from 'iframe-resizer/js/iframeResizer'
import {mapGetters} from "vuex";

export default {
    name: "PzUnnax",
    props: {
        route: {
            require: true,
            default: null,
            type: [String, URL]
        },
        redirect: {
            require: true,
            default: 'Prestamos',
            type: [String]
        },
    },
    data() {
        return {
            frame_route: this.route,
            height: '600px',
        }
    },
    computed: {
        ...mapGetters(['alias'])
    },
    watch: {
        route(value) {
            this.frame_route = value;
        },
        frame_route(value) {
            console.log('change form route');
            console.log(value);
        },
    },
    methods: {
        onMessage(messageData) {
            let message = JSON.parse(messageData.message);
            this.$store.dispatch('loader/up', {trigger: this.$options.name + '.onMessage'});
            this.parseMessages(message).finally(
                () => {
                    this.$store.dispatch('loader/down', {trigger: this.$options.name + '.onMessage'});
                }
            );
        },
        parseMessages(message) {
            return new Promise((resolve) => {
                switch (message.t) {
                    case 'ready':
                        console.log('ready');
                        resolve();
                        break;
                    case 'close':
                        this.$router.push({name: this.redirect});
                        resolve();
                        break
                    default:
                        console.warn('[PZ Warn][Docusing]Event "' + message.t + '" is not define.');
                        resolve();
                        break
                }

            });
        },
        onResize(messageData) {
            this.height = messageData.height + 'px';
        },
        change(mutations) {
            mutations.forEach((mutation) => {
                console.log(mutation)
                console.log(mutation.type)
                if (mutation.type === 'src') {
                    console.log('src');
                }
            });
        },

        iframeLoaded() {
            iFrameResize({
                log: false,
                checkOrigin: false,
                scrolling: 'no',
                messageCallback: this.onMessage,
                resizedCallback: this.onResize,
            }, '#unnax');
        }
    }
}
</script>
<style lang="scss" scoped>
iframe:empty {
  background-color: #fff;
  background-image: url('~@/assets/images/loader/unnax.svg');
  background-repeat: no-repeat;
  background-size:60%;
  background-position:100%;
	
	
}
</style>