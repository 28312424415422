<template>
  <el-form-item    
    :prop="prop"
    :rules="rule"
    :error="errors"
  >
    <el-button	  
      slot="label"
      type="text"
      class="bottom-link pz-font-size-bs"
      @click="centerDialogVisible=true"
    >
      {{ name ? $t('see_terms',{name:name}):$t('see_terms_and_conditions') }}
    </el-button>
    <el-checkbox
      v-model="terms"
      :label="name ?$t('accept_terms',{name:name}): $t('accept_terms_and_conditions')"
      @change="handleChange"
    />

    <el-dialog
      :title="name ? name: $t('terms_and_conditions')|capitalize({ onlyFirstLetter: true })"
      :visible.sync="centerDialogVisible"
      width="80%"
      center
    >
      <slot />
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          @click="centerDialogVisible=false"
        >{{ $t('close') }}</el-button>
        <el-button
          class="button-larger-effect"
          type="primary"
          @click="handleChange(true)"
        >{{ name?$t('i_accept_terms',{name:name}):$t('i_accept_terms_and_conditions') }}</el-button>
      </span>
    </el-dialog>
  </el-form-item>
</template>

<script>

    export default {
        name: "PzTermsAndConditions",
        components: {},

        props: {
            errors: {type: String,default: null},
            prop: {type: String, default: null},
            value: {type: Boolean, default: false},
            name: {type: String, default: null},
        },
        data() {
            var check_Terms = (rule, value, callback) => {
                if (!(this.terms)) {
                    return callback(new Error(this.$t('rules.should_accept_terms', {name: this.name})));
                } else {
                    return callback();
                }
            };
            return {
                terms: false,
                centerDialogVisible: false,
                rule: [{validator: check_Terms, trigger: 'change'}]
            }

        },
        watch: {
            value(value) {
                this.setTerms(value);
            }
        },
        created() {
            this.setTerms(this.value);
        },
        methods: {
            handleChange(val) {
                this.terms = val;
                this.$emit('input', this.terms);
                this.centerDialogVisible = false;
            },
            getInput() {
                return this.terms;
            },
            setTerms(value) {
                const input = this.getInput();
                if (input === value) return;
                this.terms = value;
            }
        }
    }

</script>

<style scoped>

</style>