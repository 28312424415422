<template>
  <div>
    <el-col v-if="template_">
      <label class="s-small">
        <i class="el-icon-download large"> &nbsp;</i>
        <a
          class="s-small bottom-link"
          :href="template_"
          target="_blank"
        >
          {{ $t('forms_flujo.form_documentacion_empresa.download_template') }}
        </a>
      </label>
    </el-col>
    <el-upload
      drag
      action=""
      :show-file-list="false"
      :http-request="addAttachment"
    >
      <el-col
        v-if="!file_"
        :span="24"
      >
        <el-col :span="24">
          <i class="el-icon-upload" />
        </el-col>
        <span class="el-upload__text">
          {{ $t('forms_flujo.form_documentacion_empresa.drag') }}
          <em>{{ $t('forms_flujo.form_documentacion_empresa.click') }}</em>
        </span>
      </el-col>
      <el-col
        v-else
        :span="24"
        class="el-upload__text pz-file-uploaded"
      >
        <el-col
          :span="24"
          class="pz-file-icon"
        >
          <pz-svg-icon
            :icon-class="icon"
            :style="'color:'+ secondary"
          />
        </el-col>
        <span class="el-upload__text"> {{ file_.name|truncate(60) }}</span>
      </el-col>
			
      <el-col
        v-if="tip_"
        slot="tip"
        class="pz-font-size-sm"
        style="transform: translateY(-18px)"
      >
        <i :class="'el-icon-'+tipIcon+' pz-font-size-bs  pz-color-primary'" />
				&nbsp;&nbsp;&nbsp;&nbsp;
        <span> {{ tip_ }}</span>
      </el-col>
    </el-upload>
  </div>
</template>

<script>
import mime from 'mime-types';

const variables = require(`@/assets/sass/variables_clients/${process.env.VUE_APP_CLIENT}/variables.json`);

export default {
    name: "PzUpload",
    props: {
        file: {
            type: File,
            default: () => {
                return null
            }
        },
        tip: {
            type: String,
            default: null
        },
        tipIcon: {
            type: String,
            default: 'question',
            enum: ['warning', 'info', 'question']

        },
        template: {
            type: String,
            default: null
        },

    },
    data() {
        return {
            file_: null,
            tip_: null,
            template_: null,
            icon: ''
        }
    },
    computed: {
        secondary() {
            return variables['--color-secondary'];
        }
    },
    watch: {
        tip(value) {
            if (value === this.tip_) return;
            this.tip_ = value;
        },
        value(value) {
            if (value === this.file_) return;
            this.file_ = value;
        },
        template(value) {
            if (value === this.template_) return;
            this.template_ = value;
        }
    },
    created() {
        this.tip_ = this.tip;
        this.template_ = this.template;
        this.file_ = this.file;
    },
    methods: {
        addAttachment(request) {
            this.file_ = request.file;
            this.$emit('input', this.file_);
            this.icon = this.getFileTypeExtencions(request.file.type);
        },
        getFileTypeExtencions(fileType) {
            return mime.extension(fileType)
        },
        getFileExtencionsType(fileType) {
            return mime.extension(fileType)
        },
    }
}
</script>
<style lang="scss" scoped>
.el-upload-dragger:hover * {
  .el-icon-upload {
    color: $--color-primary;
  }
}
</style>