<template>
  <el-row>
    <el-form-item
      :label="label?label:$t('commons.phone_number.label')"
      prop="phone_number"
      :class="{'is-required':required}"
      :rules="required?rule.phone:rule.number"
      :error="error"
    >
      <el-input
        v-model="result.numero"
        :disabled="disabled"
        :placeholder="label?label:$t('commons.phone_number.label')"
        class="input-with-select"
      >
        <el-select
          v-if="!noCountrySelector"
          slot="prepend"
          v-model="countryIso"
          :disabled="disabled||disabledCountrySelector"
          class="pz-code-country"
          filterable
          :filter-method="filterMethod"
          placeholder=""
          @change="handleChange"
        >
          <flag
            v-if="!noFlags"
            slot="prefix"
            class="pz-code-country-flag"
            :iso="countryIso"
          />
          <el-option
            v-for="item in filteredOptions"
            :key="item.iso"
            :label="'+' + item.calling_code"
            :value="item.iso"
            placeholder="+___"
          >
            <span style="float: left">
              <flag
                v-if="!noFlags"
                slot="prefix"
                :iso="item.iso"
              />
              {{ (showCodeOnList ? '(+' + item.calling_code + ') ' : '') + item.nombre }}
            </span>
          </el-option>
        </el-select>
      </el-input>
    </el-form-item>
  </el-row>
</template>
<script>
import {getPaises} from "@/routes/api/resources";
import {arrayFind, isEmpty, isString, isNumber} from "@/commons/utils/functions";

export default {
    name: "PzPhoneNumber",
    props: {
        // eslint-disable-next-line vue/require-prop-types
        value: {
            default() {
                return {
                    code: '',
                    numero: ''
                }
            }
        },
        label: {
            type: [String, Number],
            default: null
        },
        error: {type: String, default: null},
        disabled: {type: Boolean, default: false},
        disabledCountrySelector: {type: Boolean, default: false},
        defaultCountryCode: {type: String, default: null},
        noFlags: {type: Boolean, default: false},
        required: {type: Boolean, default: false},
        noCountrySelector: {type: Boolean, default: false},
        showCodeOnList: {type: Boolean, default: false},
        onlyCountries: {type: Array, default: null},
        ignoredCountries: {type: Array, default: Array},
        noValidatorState: {type: Boolean, default: false},
    },

    data() {
        var checkPhoneNumber = (rule, value, callback) => {
            if (isEmpty(this.result) || ((isEmpty(this.result.numero) && isEmpty(this.result.numero)))) {
                return callback(new Error(this.$t('rules.field_mandatory', {field: this.$t('')})));
            }
            if (isEmpty(this.result.numero)) {
                return callback(new Error(this.$t('rules.field_mandatory', {field: this.$t('rules.country_code')})));
            }
            if (isEmpty(this.result.code) && !this.noCountrySelector) {
                return callback(new Error(this.$t('rules.field_mandatory'), {field: this.$t('rules.country_code')}));
            }
            return callback();
        };
        var checkNumber = (rule, value, callback) => {
            if (!isEmpty(this.result.numero) && !isNumber(this.result.numero)) {
                return callback(new Error(this.$t('rules.field_number', {field: this.$t('')})));
            }

            return callback();
        }
        return {
            countries: [],
            filteredOptions: [],
            countryIso: '',
            result: {
                code: '',
                numero: ''
            },
            rule: {
                phone: [
                    {validator: checkPhoneNumber, trigger: 'blur'},
                    {validator: checkNumber, trigger: 'blur'}],
                number: [{validator: checkNumber, trigger: 'blur'},]
            },
        }
    },
    watch: {
        countryIso(newValue, oldValue) {
            if (newValue === oldValue) return;
            let pais = this.getCode(newValue);
            this.result.code = pais.calling_code;
            this.emitValues(this.result);
        },
        value(newValue) {
            this.setValue(newValue);
        },
        defaultCountryCode(newValue, oldValue) {
            if (newValue === oldValue) return
            this.setLocale(newValue);
        },
        result(value) {
            this.emitValues(value);
        }
    },
    created() {
        this.fetchData();
    },
    methods: {
        // eslint-disable-next-line no-unused-vars
        handleChange(val) {
            this.filteredOptions = this.countries;
        },
        setValue(newValue) {
            if ((newValue === this.result) || isEmpty(newValue)) return
            if (isString(newValue) || isNumber(newValue)) {
                this.result.numero = newValue
            } else {
                this.result = newValue
                if (!isEmpty(this.result.code)) {
                    let pais = this.getIso(newValue.code);
                    this.countryIso = pais ? pais.iso : null;
                }
            }
        },
        setLocale(locale) {
            if (locale) {
                let pais = this.getIso(locale);
                this.countryIso = pais.iso;
                this.result.code = locale
                this.emitValues(this.result);
            }
        },
        emitValues(payload) {
            this.$emit('input', payload);

        },
        filterMethod(query) {
            query = query.replace('+', '');
            this.filteredOptions = this.countries.filter(option => {
                return (option.nombre.toString().toLowerCase().indexOf(query.toLowerCase()) > -1
                    || option.calling_code.toString().indexOf(query) > -1);
            })
            return this.filteredOptions;
        },
        getCode(iso) {
            return arrayFind(this.countries, (elem) => {
                return (elem.iso).toString().toLowerCase() === iso.toString().toLowerCase();
            });
        },
        getIso(code) {
            code = code.replace('+', '');
            return arrayFind(this.countries, (elem) => {
                return (elem.calling_code).toString() === code.toString();
            });
        },
        fetchData() {
            this.$store.dispatch('loader/up', {trigger: this.$options.name});
            let countries = getPaises().then((res) => {
                this.filteredOptions = this.countries = res.data;
                this.setValue(this.value);
                if (isEmpty(this.result.code) && !this.defaultCountryCode) {
                    console.log('current country code');
                    this.$store.dispatch('app/getCountryFrom', this.countries).then((pais) => {
                        if (pais) {
                            this.countryCode = pais.iso;
                        }
                    })
                } else if (isEmpty(this.result.code)) {
                    console.log('country default code');
                    this.setLocale(this.defaultCountryCode);
                }
            }).catch(() => {
                this.countryCode = '';
            });
            Promise.all([countries]).
                finally(() => {
                    this.$store.dispatch('loader/down', {trigger: this.$options.name});
                });

        },
    }
}
</script>
<style lang="scss">
.pz-code-country .el-input.el-input--prefix.el-input--suffix input {
  width: 120px;
}

.pz-code-country * {
  .el-input__icon.el-input__validateIcon {
    display: none
  }
}

.pz-code-country-flag {
  top: 7px !important;
}
</style>